import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";

import { v4 as uuid } from "uuid";
import { ReactComponent as RoundedCheck } from "../../../assets/Rounded-Check.svg";

import { toast } from "react-toastify";

import useWorkflowStore from "../../../store/useWorkflowStore";
import Axios from "../../../utils/Axios";

// import ValidationControls from "./controls/multiclass/ValidationControls";
import useKeyboard from "../../../hooks/useKeyboard";
import TaggedText from "../validation/TaggedText";
import RawText from "../validation/RawText";
import useEntityStore from "../../../store/entityStore";

// import { getClassesInAlphabeticOrder } from "../stage-2/helper";

export interface StageParaProps {
    para: any;
    stage: number;
    index: number;

    activePara: number;
    setActivePara: (value: number) => void;

    readOnly?: boolean;
    keyboardEnabled?: boolean;
    setKeyboardEnabled?: any;

    setCount: any;

    fileInfo: any;
}

const NERPara: React.FC<StageParaProps> = (props) => {
    const { activePara, setActivePara, para, index } = props;

    const textLimit = 550;

    //let [expanded, setExpanded] = useState(false);
    //const activePara = useEntityStore(state=>state.activePara);
    // const setActivePara = useEntityStore(state => state.setActivePara);
    const paras = useEntityStore((state) => state.paras);
    const setParas = useEntityStore((state) => state.setParas);
    let [fade, setFade] = useState(false);
    let [accept, setAccept] = useState<boolean | null>();
    let [loading, setLoading] = useState<boolean>(false);

    const { currentWorkflow } = useWorkflowStore();
    const isActive = index === activePara;

    const [isMounted, setIsMounted] = useState<boolean>(false);

    //Keyboard Shortcuts
    const text: string = isActive ? para.paraData : para.paraData.slice(0, textLimit);
    const [letters, setLetters] = useState<any[]>();
    const divRef = useRef<HTMLDivElement>(null);
    const Enter = useKeyboard({ keyCode: "Enter" });
    const BackSpace = useKeyboard({ keyCode: "Tab" });
    let waitFor = (duration: number) => {
        return new Promise((resolve) => setTimeout(resolve, duration));
    };

    useEffect(() => {
        const wait = async () => {
            await waitFor(100);
            //divRef.current?.scrollIntoView({block:"start"})
            divRef.current?.scrollIntoView({ block: "nearest" });
            if (activePara === 0) {
                divRef.current?.scrollIntoView({ block: "nearest", behavior: "smooth" });
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        };
        if (isActive) {
            wait();
        }
    }, [isActive]);

    React.useEffect(() => {
        setIsMounted(true);
    }, []);

    const getHighLightedData = () => {
        let paraData = text;
        let currentEndIndex = 0;
        const finalHTML: any[] = [];
        const validations: any[] = para?.validation;
        validations?.sort((a, b) => (a.endIndex > b.endIndex ? 1 : -1));

        let validValidations = validations;
        if (!isActive) {
            validValidations = validations?.filter((v) => {
                return v.endIndex < text.length;
            });
        }

        //console.log("=== current final html ===",finalHTML);
        //console.log('current validations',validations);
        if (!validValidations?.length) {
            const FirstPart = {
                text: paraData,
                entity: null,
                topOffset: currentEndIndex,
            };
            finalHTML.push(FirstPart);
        }

        validValidations?.length &&
            validValidations?.forEach((v, i) => {
                const startIndex = v.startIndex;
                const endIndex = v.endIndex;

                if (endIndex > text.length) {
                    if (i === 0) {
                        const FirstPart = {
                            text: paraData,
                            entity: null,
                            topOffset: 0,
                        };
                        finalHTML.push(FirstPart);
                    }
                    return;
                }

                const FirstPart = {
                    text: paraData.slice(currentEndIndex, startIndex),
                    entity: null,
                    topOffset: currentEndIndex,
                };

                const HighlightedPart = {
                    text: paraData.slice(startIndex, endIndex),
                    entity: v.entityId,
                    validation: v,
                };

                const lastPart = {
                    text: paraData.slice(endIndex),
                    topOffset: endIndex,
                    entity: null,
                };
                finalHTML.push(FirstPart);

                finalHTML.push(HighlightedPart);

                if (!isActive && i < validValidations?.length - 1 && validValidations[i + 1].startIndex > text.length) {
                    finalHTML.push({ lastPart });
                }
                if (i === validValidations?.length - 1) finalHTML.push(lastPart);

                currentEndIndex = endIndex;
            });
        //console.log("=== new string ===",finalHTML);
        setLetters(finalHTML);
        return finalHTML;
    };

    useEffect(() => {
        getHighLightedData();
    }, [paras, activePara]);

    return (
        <div
            ref={divRef}
            onClick={() => setActivePara(index)}
            onMouseDown={(ev) => {
                //nerStore.setCurrentParaId(paraId);
            }}
            key={para.paraId}
            className={`w-full flex  duration-200 bg-white border min-h-[8vw]
      scroll-mt-[10vw] scroll-mb-[5vw]
      rounded-[0.2vw]
      ${fade ? "opacity-0" : ""}
      ${isMounted ? "" : "opacity-0"}
      ${isActive ? "border-[#11256d] shadow-lg" : "border-transparent"}
      `}
        >
            <div className={" bg-white rounded-[0.3vw] py-[1vw] px-[1.5vw] "}>
                {/* <div className="flex border-b pb-[0.2vw] justify-between w-full">
      <p className="text-[0.8vw]   pb-1">{para?.paraSeqId} </p>
       <p className="text-[0.8vw]  pb-1">{fileInfo[para?.fileId || ""]?.fileName }</p>
        </div> */}
                <p
                    style={{
                        whiteSpace: "pre-wrap",
                    }}
                    className={`text-[1.1vw] py-[0.2vw]  leading-[2.2vw] text-justify break-words
          ${isActive ? "" : "select-none"}`}
                >
                    {/* {para.paraData} */}

                    {letters?.map((data, index) => {
                        return data.entity ? (
                            <TaggedText
                                key={data.startIndex}
                                text={data.text}
                                validation={data.validation}
                                entity={data.entity}
                                isActive={isActive}
                                isReadOnly={true}
                            />
                        ) : (
                            <RawText
                                paraId={para.paraId}
                                key={data.topOffset}
                                topOffset={data.topOffset}
                                text={data.text}
                                paraData={para.paraData}
                                isActive={isActive}
                            />
                        );
                    })}

                    {!isActive && para.paraData.length > textLimit ? "..." : ""}
                </p>
            </div>

            {/* <div className=" border my-[1vw] border-dashed border-l-1"></div> */}

            {/* <div className="w-[10%] flex items-center justify-center">

        <div className={`border rounded-full h-[3vw] w-[3vw]
                         flex items-center justify-center
                         text-[#1BA94C] 
                         ${para.saved?"bg-[#1BA94C]"
                         :"bg-white hover:bg-[#1BA94C] border-[#1BA94C]"}
                          border-[0.15vw]
                         ${para.saved?"":"cursor-pointer"}
                         duration-200
                         `}
                         
              onClick={()=>isActive && !para.saved && saveValidations()}
              >

          <RoundedCheck
          
          className={`w-[2.5vw] h-[2.5vw]
                ${para.saved?"stroke-white"
                :"stroke-[#1BA94C] hover:stroke-white duration-200"}    
          
                 `}
          />
        </div>

      </div> */}
        </div>
    );
};

export default NERPara;
