import React, { useEffect, useRef, useState } from "react";
import { getMessage } from "./util";

import useNotifications from "../../store/useNotifications";
import useAuthStore from "../../store/authStore";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import Axios from "../../utils/Axios";

//Import All Icons here
import { ReactComponent as ArrowTop } from "../../assets/arrow-top.svg";
import { ReactComponent as EmptyBell } from "../../assets/empty_bell.svg";
import { ReactComponent as Success } from "../../assets/success.svg";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { ReactComponent as Error } from "../../assets/error.svg";
import { toast } from "react-toastify";

interface NotificationsProps {
    isOpen: boolean;
    setIsOpen: any;
}

interface MessageProps {
    type: string;
    error_code: string;
    isOpen: boolean;
    createdAt: Date;
    message: string;
    index: number;
    action: any;
}

const Notification: React.FC<MessageProps> = ({ type, error_code, isOpen, createdAt, message, index, action }) => {
    const navigate = useNavigate();

    return (
        <>
            <div
                className={` ${
                    isOpen ? "" : "opacity-0"
                } delay-100 duration-500  bg-white  space-x-4  cursor-pointer py-[1vw] flex items-center justify-start h-[6vw] w-full  text-[1vw] space-x-2 ${
                    index < 9 ? "border-b" : ""
                }`}
                onClick={() => (action ? navigate("/notification_dashboard") : null)}
            >
                <div className="p-2 ">
                    {type === "error" ? (
                        <Error width={"2vw"} height={"3vw"} />
                    ) : type === "success" ? (
                        <Success width={"2vw"} height={"3vw"} />
                    ) : (
                        <Warning width={"2vw"} height={"3vw"} />
                    )}
                </div>

                <div className="text-[1vw] text-[#777777] pr-4">
                    {getMessage(error_code, message, type, true)} <br />
                    <p className="text-disableColor text-[0.8vw] ">{moment(createdAt).fromNow()}</p>
                </div>
            </div>
        </>
    );
};

const Notifications: React.FC<NotificationsProps> = ({ isOpen, setIsOpen }) => {
    let notifications = useNotifications((state) => state.notifications);
    let addNotifications = useNotifications((state) => state.addNotifications);
    let setNotifications = useNotifications((state) => state.setNotifications);
    let setIsSeen = useNotifications((state) => state.setIsSeen);
    let isSeen = useNotifications((state) => state.isSeen);
    let [showData, setShowData] = useState<boolean>();
    const mainDiv = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const { userDetails } = useAuthStore();

    useEffect(() => {
        if (notifications && notifications.length > 0) {
            // console.log("==== there was a change in notifications ===",notifications[0].userInfo && notifications[0].userInfo[userDetails?.userId || ""]?.isSeen)
            if (!notifications[0].userInfo || !notifications[0].userInfo[userDetails?.userId || ""]?.isSeen) {
                //console.log("=== setting seen as false ===")
                setIsSeen(false);
            } else if (notifications[0].userInfo.isSeen) {
                //console.log("=== setting seen as true ===")
                setIsSeen(true);
            }
        }
    }, [notifications]);

    useEffect(() => {
        // if(isOpen)
        // {
        //setShowData(true);
        const timerOutId = setTimeout(() => {
            setShowData(isOpen);
        }, 200);
        mainDiv?.current?.scrollTo({ top: 0, behavior: "smooth" });

        // }

        // if(!isOpen)
        // {
        //   setTimeout(()=>{
        //     setShowData(isOpen);
        //   },700);
        // }
        return () => clearTimeout(timerOutId);
    }, [isOpen]);

    //Mark All As Read Function Here
    const MarkAllAsRead = async () => {
        if (!notifications || !notifications.length) {
            console.log("=== Could not find notification ===");
            return;
        }

        console.log("=== Found Notifications ===");
        let nId = notifications.map((n: any) => n.notificationId);
        //Update State Before Calling API
        setIsSeen(true);
        try {
            let newNotifications: any = notifications.map((n) => {
                let temp: any = n;
                if (!temp.userInfo) {
                    temp.userInfo = {
                        [userDetails?.userId || ""]: {
                            isSeen: true,
                        },
                    };
                } else {
                    temp.userInfo[userDetails?.userId || ""] = {
                        isSeen: true,
                    };
                }
                return temp;
            });
            setNotifications(newNotifications);
            console.log("=== Notification Ids ===", nId);
            let res = await Axios.post("/notification/markas", {
                notificationIds: nId,
                key: "isSeen",
                value: true,
            });
        } catch (err) {
            setIsSeen(false);
            toast("There was an error while processing your request", { type: toast.TYPE.ERROR });
            console.log("=== Error while mark as read notifications ===", err);
        }
    };

    return (
        <div
            className={`shadow-lg flex flex-col items-center bg-white ${
                isOpen ? " h-[30vw]  border" : "h-0 overflow-hidden"
            } duration-500 w-[26vw] rounded-lg z-[60] `}
        >
            <div className={`${isOpen ? "" : "opacity-0"} duration-300 flex border-b items-center justify-between w-full min-h-[3vw] px-[1vw]`}>
                <p className="text-primaryBlue text-[1vw] font-medium">Notifications</p>
                <p
                    className={`text-[0.8vw]  duration-500  hover:underline ${isSeen ? "text-[#777777] cursor-not-allowed" : "cursor-pointer text-[#266DD3]"} `}
                    onClick={() => MarkAllAsRead()}
                >
                    Mark All as Read
                </p>
            </div>
            <div className="flex flex-col items-center w-full space-y-2 h-full ">
                {/* <ArrowTop className='duration-500'  height={isOpen?"0.6vw":"0vw"} width={true?"0.6vw":"0vw" }/> */}

                {notifications !== null && notifications?.length === 0 && (
                    <div className="h-full   flex flex-col justify-center items-center   p-[1.5vw] space-y-1">
                        <EmptyBell className="duration-500 delay-50" height={isOpen ? "8vw" : "0vw"} width={true ? "8vw" : "0vw"} />
                        <p className="text-[1.1vw] text-[#777777] font-medium">No Notifications Yet</p>
                        {/* <p className='text-center text-[0.8vw] text-[#777777]'>Stay Tuned! Important notifications about your project will show up here.</p> */}
                    </div>
                )}

                {notifications.length > 0 && (
                    <div ref={mainDiv} className={`flex flex-col items-center w-full h-[24vw] ${isOpen ? "custom_scroll" : ""} space-y-3`}>
                        {notifications &&
                            showData &&
                            notifications.map((d: any, index: number) => (
                                <Notification
                                    key={d.notificationId}
                                    type={d.type}
                                    action={d?.action || null}
                                    error_code={d.errorCode}
                                    createdAt={d.createdAt}
                                    isOpen={isOpen}
                                    message={d.message}
                                    index={index}
                                />
                            ))}
                    </div>
                )}
                {notifications.length > 0 && (
                    <div
                        className={`w-full ${
                            isOpen ? "" : " opacity-0 text-[0vw]"
                        } delay-100  duration-500  py-[0.5vw] border-t    text-[1vw] h-[10%]  flex justify-center items-end  text-center `}
                    >
                        <p onClick={() => navigate("/notification_dashboard")} className="cursor-pointer text-[#266DD3] hover:underline">
                            {" "}
                            View All
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Notifications;
