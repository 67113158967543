import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserStatistics from "../../../components/validation-summary/UserStatistics";
import useWorkflowStore from "../../../store/useWorkflowStore";
import Axios from "../../../utils/Axios";
import useAuthStore from "../../../store/authStore";
import useCompanyUsersStore from "../../../store/useCompanyUsersStore";
import { Popover, Switch } from "antd";
import template from "../../../template";
import NERTrainingParas from "./NERTrainingParas";
import NERUserStatisticsComponent from "./NERUserStatistics";
import { userInfo } from "os";
import NERAccuracy from "./NERAccuracy";
import useEntityStore from "../../../store/entityStore";

const Tab = ({ tab, active, setActive, index }: any) => {
    return (
        <div
            className={`flex translate-y-[0.15vw] items-center cursor-pointer duration-200 border-b-[0.2vw]
                            ${active ? "border-[#1BA94C] border-b " : "border-transparent"}
                            select-none
                            `}
            onClick={() => setActive(index)}
        >
            <p
                className={` mx-3 text-[1.1vw] font-medium duration-200
                            ${active ? "text-[#1BA94C]" : "text-[#000]"}`}
                style={{}}
            >
                {tab?.name}
            </p>
        </div>
    );
};

const NERValidationSummary: React.FC = () => {
    let [active, setActive] = useState(0);
    const workflow = useWorkflowStore((state) => state.currentWorkflow);
    const project = useWorkflowStore((state) => state.currentProject);
    const workflowStore = useWorkflowStore();
    const entities = useEntityStore((state) => state.entities);
    let userInfo = useAuthStore((state) => state.userDetails);
    let { companyUsers } = useCompanyUsersStore();

    const [pieloading, setPieLoading] = useState(true);
    const [options, setOptions] = useState<any>();
    const [seriesData, setSeriesData] = useState<Array<any>>();
    const [heatmapLoading, setHeatmapLoading] = useState(true);
    const [max, setMax] = useState(0);
    const [pieData, setPieData] = useState<any>(null);
    // const [data, setData] = useState([]);
    const [tabs, setTabs] = useState<any[]>([
        {
            name: "Accuracy",
            component: NERAccuracy,
            data: null,
            userInfo: userInfo,
            loading: false,
        },
        {
            name: "User Statistics",
            component: NERUserStatisticsComponent,
            data: null,
            userInfo: userInfo,
            loading: false,
        },
        {
            name: "Training Paragraphs",
            component: NERTrainingParas,
            data: null,
            loading: false,
        },
    ]);

    //get component

    let SummaryComp = template[project?.projectType || ""][project?.workflowType || ""]?.summary;

    console.log("=== summary ===", SummaryComp);

    const updateTabData = (activeIndex: number, data: any, loading: boolean) => {
        setTabs(
            tabs.map((tab, index) => {
                if (activeIndex === index) {
                    tab.data = data ? data : tab.data;
                    tab.loading = loading;
                }
                return tab;
            })
        );
    };

    //Call At Initial Render
    useEffect(() => {
        if (workflow) {
            //fetchPieGraphData();
        }

        if (workflow && companyUsers.length > 0) {
            //fetchUserProject();
            //fetchData();
        }
    }, [workflow, companyUsers]);

    function shadeColor(color: string, percent: number) {
        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt((R * (100 + percent)) / 100 + "");
        G = parseInt((G * (100 + percent)) / 100 + "");
        B = parseInt((B * (100 + percent)) / 100 + "");

        R = R < 255 ? R : 255;
        G = G < 255 ? G : 255;
        B = B < 255 ? B : 255;

        R = Math.round(R);
        G = Math.round(G);
        B = Math.round(B);

        var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
        var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
        var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

        return "#" + RR + GG + BB;
    }

    const getLargeWidth = () => {
        const width = window.innerWidth;
        if (width > 1910) {
            return 1600;
        }
        if (width > 1270) {
            return 900;
        }

        if (width > 1000) {
            return 800;
        }
    };

    const getSmallWidth = () => {
        const width = window.innerWidth;
        if (width > 1900) {
            return 900;
        }
        if (width > 1270) {
            return 600;
        }

        if (width > 1000) {
            return 500;
        }
    };

    const fetchUserStats = async () => {
        let workflowId = workflowStore.currentWorkflow?.workflowId;
        updateTabData(1, null, true);
        try {
            let res_vstats: any = await Axios.post(`/ner/user-summary`, {
                workflowId,
                //   stage: stage,
                //   userId: userInfo.userId,
                //   batchNumber: batchNumber || 1,
                //   requestType
            });

            //console.log("=== Got User Stats ===", res_vstats.data);
            let vTotal = 0;
            if (res_vstats.data.result) {
                let vStats = {
                    accepted: res_vstats.data.data.accepted,
                    rejected: res_vstats.data.data.rejected,
                    totalPredicted: res_vstats.data.data.total_predicted,
                    totalParas: res_vstats.data.data.total_paras,
                    addedByUser: res_vstats.data.data.added,
                    userValidated: res_vstats.data.data.total_validated,
                };
                let data = { ...tabs[1].data, vStats };
                updateTabData(1, data, false);
                //console.log("current VStats",vStats);
            }
        } catch {
            toast("Could not get user statistics data. Please reload the page.", { type: toast.TYPE.ERROR });
        } finally {
            //updateTabData(1,null,false);
        }
    };
    const fetchUserBarGraph = async () => {
        let workflowId = workflowStore.currentWorkflow?.workflowId;
        updateTabData(1, null, true);
        try {
            let res: any = await Axios.post(`/ner/user-bar-graph`, {
                workflowId,
                //   stage: stage,
                //   userId: userInfo.userId,
                //   batchNumber: batchNumber || 1,
                //   requestType
            });

            //console.log("=== Got User Bar Graph Stats ===", res.data);
            let vTotal = 0;
            if (res.data.result) {
                const graph_data = res.data.data;
                let instanceData = Object.keys(graph_data).map((key) => {
                    return graph_data[key].instances;
                });
                let paraData = Object.keys(graph_data).map((key) => {
                    return graph_data[key].paras;
                });
                let barGraphDataOptions = {
                    //colors:["red","green"],
                    colors: [
                        function (value: any) {
                            //console.log("=== w ===",value);
                            let keys: string[] = Object.keys(graph_data);
                            let desiredKey = keys[value.dataPointIndex];
                            let color = entities.find((e) => e.entityId === desiredKey)?.color;
                            return color;
                        },
                        function (value: any) {
                            //console.log("=== w ===",value);
                            let keys: string[] = Object.keys(graph_data);
                            let desiredKey = keys[value.dataPointIndex];
                            let color = entities.find((e) => e.entityId === desiredKey)?.color || "";
                            //console.log("=== new color ===",shadeColor(color,-50));
                            return shadeColor(color, -60);
                        },
                    ],
                    xaxis: {
                        categories: Object.keys(graph_data).map((key) => {
                            return entities.find((e) => e.entityId === key)?.abbreviation;
                        }),
                    },
                    series: [
                        {
                            name: "Instances",
                            data: instanceData,
                        },
                        {
                            name: "Paragraphs",
                            data: paraData,
                        },
                    ],
                    chart: {
                        type: "bar",
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: "45%",
                            endingShape: "rounded",
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    legend: {
                        show: false,
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ["transparent"],
                    },
                    fill: {
                        opacity: 1,
                    },

                    tooltip: {
                        y: {
                            formatter: function (val: any) {
                                return +val;
                            },
                        },
                    },
                };
                let data = { ...tabs[1].data, barGraphDataOptions, smallWidth: getSmallWidth(), expandedWidth: getLargeWidth() };
                updateTabData(1, data, false);
                //console.log("current VStats",vStats);
            }
        } catch {
            toast("Could not get user statistics data. Please reload the page.", { type: toast.TYPE.ERROR });
        } finally {
            updateTabData(1, null, false);
        }
    };
    useEffect(() => {
        fetchUserStats();
        fetchUserBarGraph();
    }, []);

    return (
        <div className="w-full">
            <div className="w-full flex flex-col gap-[2vw]">
                {/* Header */}
                <div className="w-full border-b">
                    <div className="flex justify-between ">
                        <div className="flex mx-5 gap-[1vw] ">
                            {tabs.map((tab: any, index) => {
                                return <Tab key={index} tab={tab} active={active === index} setActive={setActive} index={index} />;
                            })}
                        </div>
                        <Popover content="Refresh graphs">
                            <div
                                style={{
                                    alignSelf: "center",
                                    justifySelf: "flex-end",
                                }}
                                className="duration-700 rounded -mr-2  p-2 cursor-pointer flex items-center mr-5 border-1 hover:scale-105"
                                onClick={() => {
                                    const isLoading = tabs.find((tab) => tab.loading);
                                    if (isLoading) return;
                                    fetchUserBarGraph();
                                    fetchUserStats();
                                }}
                            >
                                <img src="/images/reload.svg" alt="refresh" className="w-[3vw]" />
                            </div>
                        </Popover>
                    </div>
                </div>

                <div style={{ margin: "0 25px 0 40px" }}>
                    {React.createElement(tabs[active].component, {
                        tabData: tabs[active],
                        setData: updateTabData,
                    })}
                    {/* {
              active===1 ?<UserStatistics
              requestType={1}
              setRequestType={null}
             stage={1} batchNumber={1} users={users} />  :null
            } */}

                    {/* {
            active===2?<NERTrainingParas hasStages={true} />:null
           } */}
                </div>
            </div>
        </div>
    );
};
export default NERValidationSummary;
